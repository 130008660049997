import React, { useContext } from "react"
import { graphql, Link, navigate } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { StoreContext } from "../context/StoreContext"
import cover from "../images/upgrade/cover.gif"

const UpgradePage = props => {
  const { addProductToCart } = useContext(StoreContext)
  console.log(props.data)
  console.log("fff", props)
  let handle = ""
  if (props.location.state) {
    handle = `design/${props.location.state.designer}`
  } else {
    handle = ""
  }
  return (
    <Layout>
      <SEO title="Upgrade" />
      <section className="section">
        <div className="container">
          <div
            className="box"
            stle={{ marginRight: "5rem", marginLeft: "5rem" }}
          >
            <h2
              style={{ margin: 0, padding: 0, borderRadius: "10px" }}
              className="is-size-1 has-text-centered has-text-bold has-text-warning has-background-black-bis	"
            >
              GET YOUR NEXT PILLOW 20% OFF!
            </h2>
            <hr />
            <div className="columns is-vcentered is-multiline">
              <div className="column">
                <figure className="image">
                  <img style={{ borderRadius: "10px " }} src={cover} />
                </figure>
              </div>
              <div className="column ">
                <h1
                  className="is-size-1 has-text-centered has-text-bold"
                  style={{ color: "rgb(2, 176, 241)" }}
                >
                  20% OFF - TODAY ONLY!
                </h1>
                <hr />
                <h3 className="is-size-3 has-text-centered">
                  Do you have another dog or cat? We are offering a 20% OFF deal
                  on your next pillow{" "}
                  <span
                    className="has-text-danger	"
                    style={{ fontWeight: "bold" }}
                  >
                    TODAY
                  </span>{" "}
                  only!
                </h3>

                <p className="has-text-centered" style={{ marginTop: 50 }}>
                  <Link to={`/${handle}`}>
                    <button
                      className="button is-primary is-large is-fullwidth"
                      style={{ marginRight: 10, marginBottom: 30 }}
                    >
                      <b>ACTIVATE DEAL!</b>
                    </button>
                  </Link>
                  <Link to="/cart">
                    <button className="button is-default is-large">
                      GO TO CART
                    </button>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    shopifyProduct(tags: { eq: "upsell" }) {
      title
      description
      descriptionHtml
      images {
        localFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      variants {
        shopifyId
        price
      }
    }
    mockup: file(relativePath: { eq: "upgrade/mockup.png" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mockup2: file(relativePath: { eq: "upgrade/mockup2.png" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mockup3: file(relativePath: { eq: "upgrade/mockup3.png" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mockup4: file(relativePath: { eq: "upgrade/mockup4.png" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default UpgradePage
